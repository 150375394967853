// @ts-nocheck
/* eslint-disable no-console */
import splashLogo from '../../assets/logo2.png';
async function main() {
    const element = document.getElementById('splash');
    if (element == null) {
        throw new Error("Could not get element with id='splash'");
    }
    const img = document.createElement('img');
    const div = document.createElement('div');
    div.style.backgroundColor = '#EEE';
    div.style.width = '100vw';
    div.style.height = '100vh';
    div.style.top = '0';
    div.style.left = '0';
    div.style.display = 'flex';
    div.style.justifyContent = 'center'; /* align horizontal */
    div.style.alignItems = 'center'; /* align vertical  */
    div.style.zIndex = '10000';
    div.style.position = 'absolute'; /* align vertical  */
    img.style.maxWidth = '50vw';
    img.src = splashLogo;
    element.appendChild(div);
    div.appendChild(img);
    // webpack code splitting: https://webpack.js.org/guides/code-splitting/
    await import(/* webpackChunkName: 'myMain', webpackPrefetch: true */ './index');
}
main();
